td ul li .nav-link {
  padding-top: 2px;
  padding-bottom: 2px;
}

label {
  margin-top: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.sidebar {
  background-color: #0b423e;
}

.sidebar .nav-link.active {
  background-color: #032d20;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #ffc107;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #ffc107;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffc107;
}

.nav-item {
  padding-right: 3px;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.react-datetime-picker__clear-button__icon {
  stroke: #888;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__clear-button__icon {
  stroke: #888;
}

@media print {
  .table-impressao {
    margin: 3em;
  }
}

.css-g1d714-ValueContainer {
  max-height: 100px;
  overflow-y: auto !important;
}

.toast {
  z-index: 999;
}

.c-legacy-theme a {
  color: #23282c;
}

.c-legacy-theme .c-sidebar {
  color: #fff;
  background: #20313f;
}

@page {
  size: auto;
}

.select_company_group {
  min-width: 250px;
  border-color:white;
}
.table svg {
  margin-right: 5px;
}

button svg {
  margin-top: -3px;
  margin-right: 3px;
}

.mgr-5 {
  margin-right: 5px;
}